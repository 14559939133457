<template>
  <div class="home">
    <div class="left-third">
      <transition name="text-slide"
                  mode="out-in">
        <div class="left-content"
             v-if="showText">

          <div class="title"
               :class="{active:selectIndex === index}"
               v-for="(item,index) in list"
               :key="index"
               @click="click(index)">
            <img :src="item.icon"
                 alt=""
                 class="icon">
            <span>
              {{item.name}}
            </span>

          </div>

        </div>
      </transition>
    </div>
    <div class="right-two-thirds">
      <transition name="image-fade"
                  mode="out-in">

        <div v-show="showImage"
             class="image-box">
          <div v-show="selectIndex === 0"
               @wheel.stop>
            <div class="">
              <h2>下载XTab助理教程</h2>
              <blockquote>
                <p>XTab助理是我们推出的一款绿色、无广告的工具，能够帮助用户一键安装绝大部分浏览器并装上XTab新标签页，用户再也无需担心进去钩鱼网站让电脑上出现莫名的软件和广告。</p>
              </blockquote>
              <h3>安装帮助</h3>
              <ol>
                <li>
                  <p>点击
                    <a target=""
                       class="link"
                       href="https://go.xtab.link/download/XTab.exe">下载 xtab 助理</a>，
                  </p>
                </li>
                <li>
                  <p>安装xtab 选择要安装的浏览器（选择谷歌后，qq浏览器也会同步安装；选择360后，360极速浏览器也会同步安装）</p>

                  <img src="@/assets/InstallationGuide/install_home.jpg"
                       class="image-border"
                       style="width: 300px;" />
                </li>
                <li>
                  <p>点击 <span class="border-text">开始安装</span> ，安装完毕后可直接关闭安装程序</p>
                </li>
              </ol>
            </div>
          </div>

          <div v-show="selectIndex === 1"
               @wheel.stop>

            <div class="">
              <h2>Edge浏览器安装教程</h2>
              <h3>🌏在线安装</h3>
              <ol>
                <li>
                  <p>进入 <a target="black"
                       class="link"
                       href="https://microsoftedge.microsoft.com/addons/detail/gfechfioaanebemclajhfgkfaopcaibo">Microsoft Edge 拓展商店</a>，点击获取</p>
                </li>
                <li>
                  <p>点击弹出层当中的 <span class="border-text">添加拓展</span> </p>

                  <img src="@/assets/InstallationGuide/edge/edge1.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>如果新建标签页不是XTab新标签页，则回到管理拓展页面（浏览器导航栏输入<span class="border-text">edge://extensions/</span> ），点击开启按钮启用 XTab 新标签页面</p>
                  <img src="@/assets/InstallationGuide/edge/edge2.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>将XTab新标签页设置为起始页，点击新建标签页按钮（或快捷键 Ctrl+T），选择喜欢的主题开启属于你的Tab页吧~ </p>

                </li>

              </ol>
              <h3>🔗离线安装</h3>
              <p>如果您的 Edge <span class="border-text">获取Microsoft Edge 拓展</span> 访问不了，可以使用离线安装</p>
              <ol>
                <li>
                  <p>点击 <a target=""
                       class="link"
                       href="https://go.xtab.link/download/xtab_offline.zip">下载离线安装包</a> ，右键解压文件以获得 <span class="border-text">.crx</span> 文件</p>
                </li>
                <li>
                  <p>在浏览器页面点击右上角<span class="border-text">...</span> ，或者浏览器输入<span class="border-text">edge://extensions/</span> , 进入管理拓展程序页面</p>
                </li>
                <li>
                  <p>开启左侧边栏中的 <span class="border-text">开发人员模式</span> ，刷新页面</p>
                  <img src="@/assets/InstallationGuide/edge/edge3.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>将 <span class="border-text">.crx</span>文件拖拽到管理拓展程序页面，松开鼠标左键</p>
                  <img src="@/assets/InstallationGuide/edge/edge4.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>点击 <span class="border-text">添加拓展</span> ，即可完成离线安装</p>
                  <img src="@/assets/InstallationGuide/edge/edge1.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>启用 <span class="border-text">XTab 标签页面</span>， 点击开启按钮</p>
                  <img src="@/assets/InstallationGuide/edge/edge2.jpg"
                       class="image-border" />
                </li>
              </ol>
            </div>

          </div>
          <div v-show="selectIndex === 2"
               @wheel.stop>

            <div class="">
              <h2>Chrome浏览器安装教程</h2>
              <h3>🌏在线安装</h3>
              <blockquote>
                <p>进入 <a target="black"
                     class="link"
                     href="https://chromewebstore.google.com/detail/kolgdodmgnnhnijmnnidfabnghgakobl">Chrome拓展商店XTab页面</a>，点击安装即可</p>

                <p>如果没有成功，则进入浏览器输入在顶部搜索栏输入<span class="border-text">chrome://extensions/</span>进入拓展管理
                  启动 <span class="border-text">XTab 新标签页面</span>,点击开启按钮</p>

                <img src="@/assets/InstallationGuide/chrome/1.jpg"
                     class="image-border" />
              </blockquote>
              <h3>🔗离线安装</h3>
              <blockquote>
                <p>如果您的 Chrome 应用商店访问不了，可以使用离线安装</p>
              </blockquote>
              <ol>
                <li>
                  <p>点击 <a target=""
                       class="link"
                       href="https://go.xtab.link/download/xtab_offline.zip">下载离线安装包</a> ，右键解压文件以获得 <span class="border-text">.crx</span> 文件</p>
                </li>
                <li>
                  <p>进入Chrome浏览器，进入管理拓展页面

                  </p>

                  <img src="@/assets/InstallationGuide/chrome/3.jpg"
                       class="image-border" />

                </li>
                <li>
                  <p>开启右上角的 <span class="border-text">开发者模式</span>，并刷新页面</p>
                  <img src="@/assets/InstallationGuide/chrome/4.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>将 <span class="border-text">.crx</span> 文件拖拽到管理拓展程序页面，松开鼠标左键</p>
                  <img src="@/assets/InstallationGuide/chrome/5.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>点击 <span class="border-text">添加拓展程序</span>，即可完成离线安装

                  </p>
                  <img src="@/assets/InstallationGuide/chrome/1.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>启动 <span class="border-text">XTab 新标签页面</span>,点击开启按钮</p>
                  <img src="@/assets/InstallationGuide/chrome/2.jpg"
                       class="image-border" />
                </li>
              </ol>
            </div>

          </div>
          <div v-show="selectIndex === 3"
               @wheel.stop>
            <div class="">
              <h2>搜狗浏览器安装教程</h2>
              <h3>🌏在线安装</h3>
              <blockquote>
                <p>暂不支持在线安装，推荐使用<a target=""
                     class="link"
                     @click="selectIndex = 0">一键安装</a></p>
              </blockquote>
              <h3>🔗离线安装</h3>
              <ol>
                <li>
                  <p>点击 <a target=""
                       class="link"
                       href="https://go.xtab.link/download/xtab_offline.zip">下载离线安装包</a> ，右键解压文件以获得 <span class="border-text">.crx</span> 文件</p>
                </li>
                <li>
                  <p>在浏览器页面点击右上角<span class="border-text">...</span>，再点击<span class="border-text">应用中心</span>，进入<span class="border-text">管理拓展程序</span>页面; 或直接在浏览器顶部搜索框输入 <span class="border-text">se://extensions</span> ，点击访问也可进入</p>
                </li>
                <li>
                  <p>开启左侧边栏中的 <span class="border-text">开发人员模式</span>，刷新页面</p>
                  <img src="@/assets/InstallationGuide/so/1.jpg"
                       class="image-border" />

                </li>
                <li>
                  <p>将 <span class="border-text">.crx</span> 文件拖拽到管理拓展程序页面，松开鼠标左键</p>
                  <img src="@/assets/InstallationGuide/so/2.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>点击 <span class="border-text">添加拓展</span>，即可完成离线安装</p>
                  <img src="@/assets/InstallationGuide/so/3.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>启用 <span class="border-text">XTab 标签页面</span>， 点击开启按钮</p>
                  <img src="@/assets/InstallationGuide/so/4.jpg"
                       class="image-border" />
                </li>
              </ol>
            </div>
          </div>
          <div v-show="selectIndex === 4"
               @wheel.stop>
            <div class="">
              <h2>360浏览器/360极速浏览器 安装教程</h2>
              <h3>🌏在线安装</h3>
              <ol>
                <li>
                  <p>进入 <a target="black"
                       class="link"
                       href="https://ext.se.360.cn/#/extension-detail?id=kolgdodmgnnhnijmnnidfabnghgakobl">360浏览器拓展市场</a>，再点击立即安装。</p>
                </li>
                <li>
                  <p>点击 添加
                  </p>
                </li>
                <li>
                  <p>回到管理拓展页面（浏览器顶部地址栏输入<span class="border-text">se://plugin-manager/</span>），点击开启按钮
                    ​ </p>
                </li>
                <li>
                  <p>启用 XTab 新标签页面：点击新建标签页按钮（或快捷键 <span class="border-text">Ctrl+T</span>），选择喜欢的主题开启属于你的Tab页吧~
                  </p>
                </li>
              </ol>
              <h3>🔗离线安装</h3>

              <ol>
                <li>
                  <p>点击 <a target=""
                       class="link"
                       href="https://go.xtab.link/download/xtab_offline.zip">下载离线安装包</a> ，右键解压文件以获得 <span class="border-text">.crx</span> 文件</p>
                </li>
                <li>
                  <p>进入360浏览器，在浏览器导航栏输入 <span class="border-text">se://extensions/</span>，进入管理拓展页面</p>
                </li>
                <li>
                  <p>开启右上角的 <span class="border-text">开发者模式</span>，并刷新页面</p>
                  <img src="@/assets/InstallationGuide/360/1.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>将 <span class="border-text">.crx</span> 文件拖拽到管理拓展程序页面，松开鼠标左键</p>
                  <img src="@/assets/InstallationGuide/360/2.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>点击 <span class="border-text">添加拓展程序</span>，即可完成离线安装

                  </p>
                  <img src="@/assets/InstallationGuide/360/3.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>启动 <span class="border-text">XTab 新标签页面</span>,点击开启按钮</p>
                  <img src="@/assets/InstallationGuide/360/4.jpg"
                       class="image-border" />
                </li>
              </ol>
            </div>
          </div>

          <div v-show="selectIndex === 5"
               @wheel.stop>
            <div class="">
              <h2>QQ 浏览器安装教程</h2>
              <h3>🌏在线安装</h3>
              <blockquote>
                <p>暂不支持在线安装，推荐使用<a target=""
                     class="link"
                     @click="selectIndex = 0">一键安装</a></p>
              </blockquote>
              <h3>🔗离线安装</h3>
              <ol>
                <li>
                  <p>点击 <a target=""
                       class="link"
                       href="https://go.xtab.link/download/xtab_offline.zip">下载离线安装包</a> ，右键解压文件以获得 <span class="border-text">.crx</span> 文件</p>
                </li>
                <li>
                  <p>在浏览器页面点击右上角<span class="border-text">...</span>，再点击<span class="border-text">应用中心</span>，进入<span class="border-text">管理拓展程序</span>页面; 或直接在浏览器顶部搜索框输入 <span class="border-text">qqbrowser://extensions/</span> ，点击访问也可进入</p>
                </li>
                <li>
                  <p>开启左侧边栏中的 <span class="border-text">开发人员模式</span>，刷新页面</p>
                  <img src="@/assets/InstallationGuide/qq/1.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>将 <span class="border-text">.crx</span> 文件拖拽到管理拓展程序页面，松开鼠标左键</p>
                  <img src="@/assets/InstallationGuide/qq/2.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>点击 <span class="border-text">添加拓展</span>，即可完成离线安装</p>
                  <img src="@/assets/InstallationGuide/qq/3.jpg"
                       class="image-border" />
                </li>
                <li>
                  <p>启用 <span class="border-text">XTab 标签页面</span>， 点击开启按钮</p>
                  <img src="@/assets/InstallationGuide/qq/4.jpg"
                       class="image-border" />
                </li>
              </ol>
            </div>
          </div>

        </div>

      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstallationGuide',
  data () {
    return {
      list: [

        { name: "一键安装", icon: require('@/assets/InstallationGuide/icon/exe.png') },
        { name: "Edge", icon: require('@/assets/InstallationGuide/icon/Edge.png') },
        { name: "Google", icon: require('@/assets/InstallationGuide/icon/chrome.png') },
        { name: "搜狗", icon: require('@/assets/InstallationGuide/icon/搜狗.png') },
        { name: "360/极速X", icon: require('@/assets/InstallationGuide/icon/360安全浏览器.png') },
        // { name: "360极速X", icon: "" },
        { name: "QQ", icon: require('@/assets/InstallationGuide/icon/qq.png') },
      ],
      selectIndex: 0,
      showText: false,
      showImage: false
    };
  },
  watch: {
    '$route' () {
      this.triggerAnimation();
    }
  },
  mounted () {
    this.triggerAnimation();
  },
  methods: {
    click (index) {
      this.selectIndex = index;
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
      });

    },
    triggerAnimation () {
      this.showText = false;
      this.showImage = false;
      setTimeout(() => {
        this.showText = true;
        this.showImage = true;
      }, 100);
    }
  }
};
</script>

<style scoped>
/* 手机端样式 */
@media (max-width: 768px) {
  .home {
    display: flex;
    min-height: 100vh;
    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg3.jpg');
    background-size: cover;
  }

  .left-third {
  }

  .right-two-thirds {
    text-align: left;
  }

  .left-content {
    position: fixed;
    top: 53px;
    width: 100%;
    background-color: #33333375;

    display: flex; /* 使用 Flexbox */
    justify-content: center; /* 水平居中 */
    padding: 10px 0;
  }

  .title span {
    display: none;
  }
  .title {
    float: left;
    color: white;
    font-family: 'SHS-B';
    font-size: 14px;
    line-height: 1;
    cursor: pointer;
  }

  .title:hover {
    /* color: #409eff; */
  }

  .icon {
    width: 30px;
    height: 30px;
    margin: 0 5px;
  }

  .active {
    /* background-color: rgb(237, 152, 41); */
  }

  .image-box {
    margin-top: 120px;
    /* padding-right: 20px; */
    padding: 0 20px;
    color: white;
  }

  .image-border {
    width: 300px;
    height: auto;
    transition: opacity 1s ease-in-out;
    border-radius: 10px;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 37.4%;
    left: 50.4%;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  .home {
    display: flex;
    /* height: 100vh; */
    min-height: 100vh;

    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg3.jpg');
    background-size: cover;
  }

  .left-third {
    flex: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
    /* justify-content: center;
  align-items: center; */
    text-align: left;
  }

  .left-content {
    /* transition: transform 1s ease; */
    padding-left: 40px;
    text-align: left;

    position: fixed;
    top: 200px;
    /* 元素顶部距离视口顶部50%处 */
    /* left: 10%; 元素左侧距离视口左侧50%处 */
    transform: translate(-50%, 0%);
  }

  .title {
    color: white;
    font-family: 'SHS-B';
    font-size: 20px;
    /* font-weight: bold; */
    line-height: 1;
    cursor: pointer;

    padding-left: 10px;
    border-left: 2px solid rgba(0, 0, 0, 0);

    display: flex; /* 使用 flexbox 布局 */
    align-items: center; /* 垂直居中对齐 */
    margin: 20px 0;

    position: relative;
  }

  .title:hover {
    color: #409eff;
  }

  .icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .active::before {
    content: ''; /* 伪元素的内容 */
    width: 2px; /* 线条的宽度 */
    height: 20px; /* 线条的高度 */
    background-color: rgb(237, 152, 41);
    position: absolute; /* 绝对定位 */
    left: 0; /* 距离左边 */
    top: 50%; /* 垂直居中 */
    transform: translateY(-50%); /* 调整位置 */
    margin-right: 10px; /* 线条与内容之间的间距 */
  }
  .image-box {
    margin-top: 100px;
    padding-right: 100px;
    color: white;
  }

  .image-border {
    width: 500px;
    height: auto;
    transition: opacity 1s ease-in-out;
    border-radius: 10px;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 37.4%;
    left: 50.4%;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}

/* .active {
  border-left: 2px solid rgb(237, 152, 41);
} */
.content-text {
  font-size: 14px;
}
.link {
  color: #409eff;
  cursor: pointer;
  /* text-decoration: none; */
}
.border-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: rgba(255, 255, 255, 0.264);
  padding: 2px 5px;
  border-radius: 5px;
}
</style>


