<template>
  <div class="home">
    <div class="left-third">
      <transition name="text-slide"
                  mode="out-in">
        <div class="left-content"
             v-if="showText">
          <h1 class="title">

            {{list[index].title}}
          </h1>

          <p>{{list[index].desc}}</p>

          <div class="btn_main one"
               @click="$router.push('/download')">
            <span>开始使用</span>
          </div>
          <div class="btn_main two"
               style="left: 180px;top: -44px;"
               @click="openWeb">
            <span>快速体验</span>
          </div>
        </div>
      </transition>
    </div>
    <div class="right-two-thirds">
      <div class="buttom last"
           alt="按钮"
           @click="last" />

      <transition name="image-fade"
                  mode="out-in">
        <div v-show="showImage"
             key="about-image"
             class="image-box">

          <img :src="list[index].img"
               class="image-border" />
        </div>
      </transition>

      <div class="buttom next"
           alt="按钮"
           @click="next" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'componentx',
  data () {
    return {
      // img: require('@/assets/img/home.png')
      list: [
        {
          title: "极简模式",
          desc: "简化界面，集中核心功能，提升操作效率和用户体验",
          img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/极简模式.png'
        },
        {
          title: "游戏市场",
          desc: "轻松便捷的游戏体验，通过网页即可随时享受，适合休闲娱乐。",
          //   img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/game.png',
          img: require('@/assets/img/game.png'),
        },
        {
          title: "网络热搜",
          desc: "反映当下社会关注度和舆论热点的网络话题，通常涵盖新闻事件、娱乐八卦和社会议题等多方面内容。",
          //   img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/hot.png',
          img: require('@/assets/img/hot.png'),
        },

        {
          title: "电影推荐",
          desc: "每天为你精选一部电影，带来不同题材和风格的精彩观影体验。",
          img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/电影.png'
        },
        {
          title: "天气",
          desc: "提供实时和预测天气信息，帮助用户随时了解目标地点的天气情况",
          img: 'https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/天气.png'
        },
        {
          title: "等等....",
          desc: "欢迎自行探索更多功能。",
          img: "https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/home.png"
        },
      ],
      index: 0,



      showText: false,
      showImage: false,
      autoTimer: null
    };
  },
  watch: {
    '$route' () {
      this.triggerAnimation();
    }
  },
  mounted () {
    this.triggerAnimation();
    this.autoTimer = setInterval(() => {
      this.next("auto")
    }, 3000);
  },
  methods: {
    openWeb () {
      window.open('https://go.xtab.link/')
    },
    last () {

      // 
      if (this.index > 0) {
        this.index--;
      } else {
        this.index = this.list.length - 1;
      }

    },
    next (type) {

      if (type != "auto") {

        clearInterval(this.autoTimer);
        this.autoTimer = null
      }

      if (this.index < this.list.length - 1) {

        this.index++;
      } else {
        this.index = 0;

      }

    },
    triggerAnimation () {
      this.showText = false;
      this.showImage = false;
      setTimeout(() => {
        this.showText = true;
        this.showImage = true;
      }, 100);
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .home {
    height: 100vh;

    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg1.png');
    background-size: cover;
  }

  .left-third {
    justify-content: center;
    align-items: center;
    position: relative;
    top: 100px;
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 150px;
  }

  .buttom {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .buttom:hover {
    box-shadow: 0 0 10px #409eff;
  }

  .buttom.last {
    transform: scale(0.5);
  }

  .buttom.next {
    transform: scale(0.5) rotate(180deg);
  }

  .left-content {
    transition: transform 1s ease;
    padding: 0 40px;
    /* text-align: left; */
    color: white;
  }

  .title {
    font-family: 'SHS-B';
    font-size: 32px;
    font-weight: bold;
    color: rgb(204, 118, 1);
    display: inline-block;
  }
  .left-content p {
    height: 75px;
  }

  .image-box {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-border {
    /* width: 100%;
  height: auto; */

    width: 100%;
    height: auto;

    transition: opacity 1s ease-in-out;
    border-radius: 15px;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 37.4%;
    left: 50.4%;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  .home {
    display: flex;
    height: 100vh;

    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg1.png');
    background-size: cover;
  }

  .left-third {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
  }

  .buttom {
    cursor: pointer;
    width: 60px;
    height: 50px;
    border-radius: 50%;
    background: url(@/assets/img/arrow.png) no-repeat center center
      rgb(70, 116, 230);
    background-size: 20px 20px;
  }
  .buttom:hover {
    box-shadow: 0 0 10px #409eff;
  }

  .buttom.last {
    transform: scale(0.5);
  }

  .buttom.next {
    transform: scale(0.5) rotate(180deg);
  }

  .left-content {
    transition: transform 1s ease;
    padding: 0 40px;
    text-align: left;
    color: white;
  }

  .title {
    font-family: 'SHS-B';
    font-size: 32px;
    font-weight: bold;
    color: rgb(204, 118, 1);
  }

  .image-box {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-border {
    /* width: 100%;
  height: auto; */

    width: 700px;
    height: auto;

    transition: opacity 1s ease-in-out;
    border-radius: 15px;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 37.4%;
    left: 50.4%;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}
</style>
